<template>
    <v-container style="margin: 0; padding: 0">
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline"><span class="primary--text">PAINEL JÚRI > </span> Auxiliar</v-subheader>
                <p-breadcrumbs page="profile.jury-panel.auxiliary.home" />
            </v-col>
        </v-row>
       
        <v-row no-gutters>
            <div class="body p-2x" 
                style="width: 100%; max-width: 100%; heigth: 285px; max-heigth: 285px;">

                <v-row>
                    <v-col>
                        <v-alert
                            outlined
                            type="warning"
                            prominent
                            border="left"
                            v-if="items.length == 0"
                        >
                            Lamentamos, mas ainda não tem nenhum projecto registado.
                        </v-alert>
                    </v-col>
                </v-row>
                
                <div>
                    <template v-for="(item, i) in items">
                        <v-divider v-if="(i+1) % 2 === 0" class="ml-2x mr-2x" :key="`d-${item.project.id}`"/>
                        <Project  :key="item.project.id" :item="item"/>
                    </template>
                </div>

                <!--v-container v-if="hasMore">
                    <v-row align="center" justify="center">
                        <v-btn 
                        :loading="loading" 
                        :disabled="loading"
                        @click="fetchMore">Carregue Mais</v-btn>
                    </v-row>
                </v-container-->
            </div>
        </v-row>
    </v-container>
</template>

<script>
import { HOME } from '../../../../routes';
import Project from '../../../../components/author/jury-panel/auxiliary/Project'
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        Project
    },

    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchItems(this.filter);
    },
    computed: {
        ...mapState('juryPanel/auxiliary', ['items', 'loading',  'hasMore']),
        filter() {
            return { 
                limit: 20,
                festival: this.$route.params.festival,
            }
        },
        user() {
            if (this.$user.isAuthenticated)
                return this.$user.info;
            return null;
        },
    },
    methods: {
        ...mapActions('juryPanel/auxiliary', {
            fetchItems: 'list',
            loadMore: 'loadMore',
        }),
        fetchMore() {
            this.loadMore(this.filter);
        },

    }

}
</script>