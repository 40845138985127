<template>
    <v-row>
       <v-col cols="12">
           <v-alert v-for="(msg, id) in messages" :key="id"
            :type="msg.success ? 'success' : 'error'"
            >{{msg.message}}</v-alert>
       </v-col>
        <v-col cols="12" sm="12" lg="2" >
            <div class="p-1x" 
                style="width: 100%; max-width: 100%; height: 100%; max-height: 100%;">
                <div class="justify-center align-center text-center" >
                        <v-img :src="item.project.src" />
                    
                    <v-card-title class="justify-center align-center">
                        <span> {{ item.project.title}} </span>
                    </v-card-title>
                </div>
            </div>
        </v-col>

        <v-col cols="12" sm="12" lg="10">
            <v-row>
                <p>
                    <img
                    height="24"
                    width="24"
                    src="@/assets/i-grelha.png"
                    />
                    <span class="ml-2 primary--text text-uppercase">Critérios de Avaliação </span>
                <span><span class="ml-2 primary--text">S-</span>Sim </span>
                <span><span class="ml-2 primary--text">N-</span>Não </span>
                <span><span class="ml-2 primary--text">S/D-</span>Sem Dados</span >
                <v-btn class="ml-2 "
                    text
                    x-small @click="openProjectDialog">
                    <img
                    height="24"
                    width="24"
                    src="@/assets/i-play.png"
                    />
                    <span class="ml-1">Visualizar Obra</span>
                </v-btn>
                </p>
            </v-row>
            <v-row>
                <v-col cols="12" md="10">
                    <v-row v-if="!item.reviews">
                        <template v-for="c in criterias">
                            
                            <v-col 
                                
                                :key="c.id"
                                cols="12" sm="6">
                                
                                <v-row no-gutters>
                                    <v-col cols="12" sm="3"><p>{{ c.title }}</p></v-col>
                                    <v-col cols="12" sm="4"><div class="primary--text" v-html="c.description"></div></v-col>

                                    <v-col cols="12" sm="5">
                                        <v-row no-gutters>
                                            <v-col v-for="a in avals"
                                                    :key="a">
                                                <v-checkbox class="ml-1"
                                                    :readonly="reviewStatus === 'Avaliado' || submiting"
                                                    v-model="c.status"
                                                    color="primary"
                                                    :label="`${a}`"
                                                    :value="a"
                                                    :name="`aval${a}`"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                
                                </v-row>
                            </v-col>
                        </template>
                    </v-row>

                    <v-row v-else>
                        <template v-for="(review, i) in item.reviews.review">
                            <v-col 
                                :key="review.id"
                                cols="12" sm="6">
                                
                                <v-row no-gutters>
                                    <v-col cols="12" sm="3"><p>Critério {{ i + 1 }}</p></v-col>
                                    <v-col cols="12" sm="4"><div class="primary--text" v-html="review.criteria.criteria"></div></v-col>

                                    <v-col cols="12" sm="5">
                                        <v-row no-gutters>
                                            <v-col v-for="a in avals"
                                                    :key="a">
                                                <v-checkbox class="ml-1"
                                                    readonly
                                                    color="primary"
                                                    :label="`${a}`"
                                                    v-model="review.status"
                                                    :value="a"
                                                    :name="`aval${a}`"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                
                                </v-row>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2">
                    <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                                <v-btn color="success" v-if="item.reviews" outlined>
                                    <span>Avaliado</span>
                                </v-btn>

                                <v-btn v-else :loading="submiting" :disabled="submiting" color="primary" outlined @click="submitRating">
                                    <span>Avaliar</span>
                                </v-btn>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-col>
        <v-dialog 
            v-model="viewProjectDialog"
            max-width="500">

            <v-card class="body pa-4">
                <v-toolbar dense flat color="rgba(0, 0, 0, 0)">
                    
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            icon
                            
                            @click="closeProjectDialog">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <fieldset>
                        <legend>
                                Visualizar Obra "<span class="primary--text">{{ item.project.title }}</span>"
                        </legend>

                        <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                                <v-btn color="success">Link da Obra</v-btn>
                            </v-row>
                            
                            <v-row class="mt-6"  align="center" justify="center">
                                <v-btn color="success" @click="copyPassWord">Copiar Palavra-Passe</v-btn>
                            </v-row>
                        </v-container>
                    </fieldset>
                </v-card-text>
            </v-card>
        </v-dialog>


         <v-dialog 
            v-model="ratingDialog"
            max-width="500">

            <v-card class="body pa-4">
                <v-toolbar dense flat color="rgba(0, 0, 0, 0)">
                    
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            icon
                            
                            @click="closeProjectDialog">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <fieldset>
                        <legend>
                                Atenção
                        </legend>

                        <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                                <p class="pa-4">Por favor submeter todas avaliações da obra "<span class="primary--text">{{ item.project.title }}</span>" antes de premir o botão "Avaliar"</p>
                            </v-row>
                            
                            <v-row class="mt-6"  align="center" justify="center">
                                <v-btn color="success" @click="closeRarintDialog">OK</v-btn>
                            </v-row>
                        </v-container>
                    </fieldset>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
export default {
    props: {
        item: {type: Object}
    },
    data: () => ({
        messages: [],
        reviewStatus: '',
        submiting: false,
        avals: ['S', 'N', 'S/D'],
        criterias: [],
        viewProjectDialog: false,
        ratingDialog: false,
    }),
    created() {
        if (this.item && this.item.criterias) {
            this.criterias = _.cloneDeep(this.item.criterias);
        }

        this.reviewStatus = this.item.status;
        
    },
    computed: {
        user() {
            if (this.$user.isAuthenticated)
                return this.$user.info;
            return null;
        },
    },
    methods: {
        ...mapActions('juryPanel/auxiliary', ['submitReview']),
        openProjectDialog() {
            this.viewProjectDialog = true;
        },
        closeProjectDialog() {
            this.viewProjectDialog = false;
        },

        openRatingDialog() {
            this.ratingDialog = true;
        },
        closeRarintDialog() {
            this.ratingDialog = false;
        },

        copyPassWord() {
            alert('Nada por copiar');
        },
        mapStatus(statusCode){
            const map = { 'S': 'YES', 'N': 'NO', 'S/D': 'NO_DATA'};
            return map[statusCode];
        },
        submitRating() {
            //TODO verificar se escolheu todo criterio
            const valid = this.criterias.filter(c => !c.status).length === 0 || this.user != null;

            if (!valid) {
                this.openRatingDialog();
                return;
            }
            const reviews = this.criterias.map(c => ({
                criteria: c.id, status: this.mapStatus(c.status)
            }));
            this.messages = [];
            this.submiting = true;
            this.submitReview({ 
                data: {
                    reviews, 
                    judge: this.user.id, 
                    subscription: this.item.subscription    
                } 
            }).then((res) => {
                if (res.success) {
                    this.reviewStatus = 'Avaliado';   
                }
                this.messages = res.messages;
            }).catch(() => {
                this.messages = [{success: false, message: 'Ocorreu um erro!'}];
            }).finally(() => {
                this.submiting = false;
            });
        }
    }
}
</script>